/* BASE */

@import "./base/_base.css";

@import "./base/_app.css";
@import "./base/_util.css";

/* COMPONENTS */

@import "./components/_button.css";
@import "./components/_loading.css";
@import "./components/_image-overlay.css";
@import "./components/_file-uploader.css";

/* PAGES */

@import "./pages/_authentication.css";

/* UTIL */

@import "./utilities/_helper.css";

th:first-child {
  width: 16px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

td:first-child {
  text-align: center;
}


.btn-primary:hover{
    background-color: #00662e !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.hoverable-row {
  background-color: #FFFFFF;
}

.hoverable-row:hover {
  background-color: #f5f5f5;
}

.table-akuntansi td {
  padding: 0px !important;
}

.table-akuntansi td:first-child {
  /* padding: 28px !important; */
  /* display: flex; */
  height: 100%;
  /* border-right: 1px solid #D9D9D9; */
}

.table-akuntansi th div {
  text-align: left;
}

.table-akuntansi th:first-child div {
  text-align: center;
}

.table-akuntansi th:nth-child(3) div {
  text-align: center;
}

.table-akuntansi th:last-child div {
  text-align: center;
}

.table-akuntansi td:last-child {
  border-left: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
}