.breksi-auth-container {
  width: 100%;
  height: 100vh;
}

.breksi-auth-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url("../../assets/png/side-image.png");
}

.breksi-auth-wrapper {
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.breksi-auth-wrapper h3 {
  text-align: left !important;
}

.breksi-auth-wrapper form {
  width: 100%;
}

@media (max-width: 670px) {
  .breksi-auth-image {
    display: none;
  }
}
