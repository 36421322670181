.bg-primary {
  background-color: #019443 !important;
}

.text-primary {
  color: #019443 !important;
}

.nav-link:hover {
  color: #019443 !important;
}

.breksi-nav-icon:hover {
  color: white !important;
}

.breksi-nav-icon::after {
  color: white !important;
}

.breksi-nav-icon svg {
  fill: white !important;
  color: white !important;
  stroke: white !important;
}

td[role="cell"]:last-child {
  position: sticky;
  right: 0;
  background: white;
  box-shadow: 0 3px 3px -1px rgb(10 22 70 / 10%), 0 0 1px 0 rgb(10 22 70 / 6%);
}

th[role="columnheader"]:last-child {
  position: sticky;
  right: 0;
  background: #f5f9fc;
  box-shadow: 0 3px 3px -1px rgb(10 22 70 / 10%), 0 0 1px 0 rgb(10 22 70 / 6%);
}

input[type="number"] {
  appearance: none !important;
}

#content {
  width: 100%;
  height: 100vh;
  margin-left: 260px;
}

#topbar {
  border-bottom: none !important;
  position: fixed !important;
  width: 100%;
  z-index: 9999 !important;
}

#sidebar {
  border: none !important;
  height: 93% !important;
  position: fixed;
  left: 0;
  top: 70px;
}

.auth-title {
  font-size: 64px;
}

.btn-map-control {
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #757575;
}

.breksi-loading .modal-content {
  width: 130px !important;
  left: 80px !important;
}

.btn-sidebar-maps {
  width: 33px;
  height: 48px;
  cursor: pointer;
  position: absolute;
  top: 3%;
  right: -32px;
  z-index: 999;
  border: none;
  border-left: 1px solid #d4d4d4;
  /* border-radius: 0 8px 8px 0; */
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px 0px; */
  background: rgba(255, 255, 255, 1);
}

.css-6j8wv5-Input {
  padding: 0.35rem;
}

.hover-image .hover-image-overlay {
  width: 285px;
  height: 200px;
  position: absolute;
  bottom: 27%;
  background: rgba(209, 980, 33, 0.8);
  text-align: center;
  transition: 0.5s ease;
  border-radius: 10px;
  opacity: 0;
}

.hover-image .hover-image-icon {
  position: absolute;
  left: 60%;
  top: 55%;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  display: none;
}

.hover-image:hover .hover-image-overlay {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transition: 0.5s ease;
  opacity: 9;
}
.hover-image:hover .hover-image-icon {
  display: block;
  left: 12%;
  top: 55%;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
}

.spinner-grow-sm {
  width: 0.75rem !important;
  height: 0.75rem !important;
}

.footer-auth {
  position: relative;
  top: 20%;
}

.logo-img {
  width: 100%;
  height: 33px !important;
}

.logo-divider {
  width: 2px;
  height: 50px;
  background-color: #c4c4c4;
}

.logo-left {
  height: 45px;
}

.logo-right {
  height: 50px;
}

.text-muted-2 {
  color: #757575;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
}

@media (max-width: 680px) {
  #content {
    width: 100%;
    padding-top: 4rem;
    position: absolute;
    right: 0;
    margin-left: 0;
  }

  #sidebar {
    width: 100%;
    height: auto !important;
    top: 0;
  }

  .auth-title {
    font-size: 32px;
  }

  .btn-md {
    font-size: 0.875rem;
    padding: 0.7rem 1rem;
  }

  .footer-auth {
    top: 10%;
    flex-direction: column;
  }

  .footer-auth-caption {
    font-size: 0.75rem;
    text-align: center;
  }
}
