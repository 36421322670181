@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

html,
body {
  font-family: "Poppins", sans-serif !important;
}

.quill {
  background-color: #FFFFFF !important;
  box-shadow: 0 1px 2px rgb(50 50 71 / 8%);
}

.ql-toolbar {
  background-color: #FAFAFA !important;
  border-radius: 8px 8px 0 0 !important;
}

.ql-container {
  border-radius: 0 0 8px 8px !important;
  min-height: 250px !important;
}