.breksi-image-overlay-content {
  position: relative;
  width: 90%;
  max-width: 400px;
  height: 300px;
  margin: auto;
  overflow: hidden;
}

.breksi-image-overlay-content .breksi-image-overlay-content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  height: 300px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.breksi-image-overlay-content:hover .breksi-image-overlay-content-overlay {
  opacity: 1;
}

.breksi-image-overlay-content-image {
  width: 100%;
  height: 300px !important;
  object-fit: cover !important;
}

.breksi-image-overlay-content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.breksi-image-overlay-content:hover .breksi-image-overlay-content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.breksi-image-overlay-content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.breksi-image-overlay-content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-top {
  top: 20%;
}
